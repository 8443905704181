.review-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vehicle-image {
    width: 100%;
    object-fit: cover;
}

.input-plate {
    width: 100%;
}

.input-region-type {
    display: flex;
    width: 100%;
}

.input-region {
    width: 50%;
}

.input-type {
    width: 50%;
}

.next-button {
    margin-top: 20px;
}

.input-plate, .input-variation {
    text-transform: uppercase;
}
